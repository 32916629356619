export default () => [
  // base propbar
  {
    from: new Date('30 August 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  // 10x Nectar points shopping with Tu online
  {
    from: new Date('9 September 2024 08:00:00 UTC'),
    content: {
      text: 'Earn 10x Nectar points shopping with Tu online on mens and womens clothing! For more details',
      link: '/help/nectar?tag=tu:propbar',
      colour: 'black',
    },
  },
  // base propbar
  {
    from: new Date('15 September 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
